<template>
    <div class="custom-content">
        <el-scrollbar class="custom-wrap">
            <el-table class="customTable" :data="customList" style="width: 100%; flex: 1;" height="1%" size="medium"
                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                      :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                <el-table-column prop="content" label="基本信息" align="left">
                    <template slot-scope="scope">
                        <div class="item-content">
                            <div class="item-left">
                                <img :src="scope.row.good_img" alt="">
                            </div>
                            <div class="item-right">
                                <div class="item-title">{{scope.row.good_name}}</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="sales_30" label="30天销量" align="center"></el-table-column>
                <el-table-column prop="kefu_num" label="销售价" align="center">
                    <template slot-scope="scope">
                        ￥{{scope.row.price}}
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="操作" align="center">
                    <template slot-scope="scope">
                        <el-link type="success" :underline="false">
                            <router-link class="a-link" :to="{path: '/goods/detail', query: {g_id: scope.row.good_id}}"
                                         target="_blank">查看详情
                            </router-link>
                        </el-link>
                    </template>
                </el-table-column>
            </el-table>
            <!--            <pagination class="pages-center" :total="total" @current-change="handleCurrentChange"/>-->
        </el-scrollbar>
    </div>
</template>

<script>
    import pagination from "@/components/pagination";
    import {drill_data_map, exam_data_map} from "@/utils/apis"

    export default {
        name: "hotProduct",
        components: {
            pagination
        },
        data() {
            return {
                customList: [],
                total: 0,
                currentPageNum: 1,
                opId: Number(this.$route.query.id) || null,
                courseId: Number(this.$route.query.course_id) || null,
                platform: this.$route.meta.type,
                record: [],
            }
        },
        created() {
            this.getGoodsList()
        },
        methods: {
            getGoodsList() {
                if (this.platform == 0) {
                    let params = {
                        op_id: this.opId,
                        module: 5
                    }
                    drill_data_map(params).then(res => {
                        if (res.data) {
                            this.customList = res.data
                            this.total = res.data.total
                        }
                    }).catch(err => {
                        console.error(err)
                    })
                } else {
                    let params = {
                        module: 5
                    }
                    exam_data_map(params).then(res => {
                        if (res.data) {
                            this.customList = res.data
                            this.total = res.data.total
                        }
                    }).catch(err => {
                        console.error(err)
                    })
                }
            },
            handleCurrentChange(val) {
                this.currentPageNum = val;
                this.getGoodsList();
            },
        }
    }
</script>

<style scoped lang="scss">
    .custom-content {
        margin-top: 18px;
        height: calc(100% - 70px);
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;

        .create-btn {
            width: 118px;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #2DC079;
            border-radius: 4px;
            color: #2DC079;
            line-height: 40px;
            cursor: pointer;
            text-align: center;
            font-size: 16px;
        }

        .custom-wrap {
            flex: 1;
            height: 1%;
            display: flex;
            flex-direction: column;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
                height: 100%;

                .el-scrollbar__view {
                    margin: 0 20px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                }
            }

            .customTable {
                margin-top: 12px;
            }

            .item-content {
                display: flex;
                align-items: center;

                .item-left {
                    width: 80px;
                    height: 80px;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                .item-right {
                    margin-left: 20px;

                    .item-title {
                        width: 200px;
                        color: #333333;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
            }

            .pages-center {
                margin-bottom: 0;
            }
        }

        .reason-top {
            color: #333333;
        }

        .reason-bottom {
            color: #999999;
        }

        .a-link {
            font-size: 16px;
            color: #2461EF;
        }
    }
</style>